@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SFPRODISPLAYREGULAR.OTF');
}

.dark {
  background: black;
}

.dark h1,h2,h3 {
  color: white;
}


.dark p {
  color: #bababc;
}

html {
  margin: 0;
  font-family: 'SF Pro';
}

.text-grey {
  color: #D2D2D3;
}

.text-blue {
  color: #15D1F8;
}

.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 16px;
}

.text-lg {
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
